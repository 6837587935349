<template>
    <v-card
    class="mx-auto"
    max-width="344"
    outlined
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="overline mb-4">
            Camera
            </div>
            <v-list-item-title class="headline mb-1">
            {{ camera.name }}
            </v-list-item-title>
            
            <v-list-item-subtitle>Last ping: {{ new Date(camera.last_ping+ ' GMT+0000 (Eastern European Standard Time)').toLocaleString() }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-icon size="30" style="color: red" v-bind:class="{ online: camera.is_online }">mdi-circle</v-icon>
        </v-list-item>
    </v-card>
</template>

<script>

export default {
    
  data(){
    return {

    }
  },
  props: {
    camera: {
        type: Object,
        default: () => ({}),
    },
  },
  created: function () {
    //this.$store.dispatch(GET_CAMERA_BY_ID, {cameraId: 1})
  }
}
</script>

<style scoped>
.online {
  color: green !important;
}
</style>