import axios from 'axios'
import { GET_ALL_COMPANY_COUPON_POOLS } from './actions.type';
import {
  SET_ALL_COMPANY_COUPON_POOLS
} from "./mutations.type";

const state = {
  companyCouponPools: [],
};

const getters = {
    getAllCompanyCouponPools: state => state.companyCouponPools
};

const actions = {
  [GET_ALL_COMPANY_COUPON_POOLS](context) {
    axios({url: `/api/company-coupon-pools`, method: 'GET' })
    .then(resp => {
      context.commit(SET_ALL_COMPANY_COUPON_POOLS, resp.data.data);
    })
    .catch(err => {
      console.log(err)
    })
  },
};

const mutations = {
  [SET_ALL_COMPANY_COUPON_POOLS](state, companyCouponPools) {
    state.companyCouponPools = companyCouponPools
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
