import axios from 'axios'
import {
  CREATE_COURT,
    GET_ALL_COURTS, GET_COURT_BY_ID,
} from "./actions.type";
import {
    SET_ALL_COURTS, SET_COURT_BY_ID
} from "./mutations.type";

const state = {
    courtErrors: [],
    allCourts: {},
    courtById: {}
};

const getters = {
    getAllCourts: state => state.allCourts,
    getCourtById: state => state.courtById
};

const actions = {
    [GET_ALL_COURTS](context, { page }) {
      axios({url: `/api/courts?page=${page}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_COURTS, resp.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_COURT_BY_ID](context, { courtId }) {
      axios({url: `/api/courts/${courtId}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_COURT_BY_ID, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [CREATE_COURT](context, { court }) {
      axios({url: `/api/courts`, method: 'POST', data: court})
      .then({
        context
      })
      .catch(err => {
        console.log(err)
      })
    },
};

const mutations = {
  [SET_ALL_COURTS](state, courts) {
    state.allCourts = courts
  },
  [SET_COURT_BY_ID](state, court) {
    state.courtById = court
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
