<template>
  <v-data-table
    dense
    :headers="headers"
    :items="moneyTransactions"
    item-key="id"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
import { GET_MONEY_TRANSACTIONS_BY_USER } from '../store/actions.type';
export default {
  name: 'MoneyTransactions',
    data(){
    return {

    }
  },
  props: {
    userId: {
        type: Number,
        default: () => (0),
    },
  },
  created: function () {
    this.$store.dispatch(GET_MONEY_TRANSACTIONS_BY_USER, { userId: this.$route.params.id })
  },
  computed: {
      headers () {
        return [
        { text: 'Aantal', value: 'amount' },
        { text: 'Datum', value: 'created_at' }
      ]
    },
    moneyTransactions(){
        return this.sort(this.$store.getters.getMoneyTransactionsByUser);
    }
  },
  methods: {
    sort(transactions) {
      return transactions.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
  }
}
</script>

<style scoped>

</style>
