<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Informatie</h1>
          <v-container>
            <v-row>
              <v-col>
                <delete-user-modal></delete-user-modal>
              </v-col>
              <v-col>
                <add-money-modal></add-money-modal>
              </v-col>
              <v-col>
                <give-coupon-modal></give-coupon-modal>
              </v-col>
            </v-row>
          </v-container>

        <v-card
          class="mx-auto"
          tile
        >

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Naam</v-list-item-title>
              <v-list-item-subtitle>{{ user.first_name }} {{ user.prefix }} {{ user.last_name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Telefoonnummer</v-list-item-title>
              <v-list-item-subtitle>{{ user.phone_number }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Geld</v-list-item-title>
              <v-list-item-subtitle>
                €{{ user.money_balance }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Ace Miles</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.ace_miles_balance }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <h1>Opnames</h1>
        <recordings-by-user :userId="user.id"></recordings-by-user>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Geld transacties</h1>
        <money-transactions-history :userId="user.id"></money-transactions-history>
      </v-col>
      <v-col>
        <h1>Ace Miles transacties</h1>
        <ace-miles-transactions-history :userId="user.id"></ace-miles-transactions-history>
      </v-col>
    </v-row>
  </v-container>
    
</template>

<script>
import MoneyTransactionsHistory from '../components/MoneyTransactionsHistory.vue';
import AceMilesTransactionsHistory from '../components/AceMilesTransactionsHistory.vue';
import { GET_USER_BY_ID } from '../store/actions.type';
import DeleteUserModal from '../components/modals/DeleteUserModal.vue';
import RecordingsByUser from '../components/RecordingsByUser.vue';
import AddMoneyModal from '../components/modals/AddMoneyModal.vue';
import GiveCouponModal from '../components/modals/GiveCouponModal.vue';
export default {
  components: { MoneyTransactionsHistory, AceMilesTransactionsHistory, DeleteUserModal, RecordingsByUser, AddMoneyModal, GiveCouponModal },
  name: 'User',
    data(){
    return {

    }
  },
  created: function () {
    this.$store.dispatch(GET_USER_BY_ID, { userId: this.$route.params.id })
  },
  computed: {
    user(){
        return this.$store.getters.getUserById;
    }
  }
}
</script>

<style scoped>

</style>
