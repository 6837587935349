// FOR AUTH
export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';

//FOR USERS
export const GET_ALL_USERS = 'getAllUsers';
export const GET_USER_BY_ID = 'getUserById';
export const CREATE_USER = 'createUser';
export const UPDATE_USER = 'updateUser';
export const DELETE_USER = 'deleteUser';
export const IMPORT_USERS = 'importUsers';

//FOR GROUPS
export const GET_ALL_GROUPS = 'getAllGroups';
export const GET_GROUP_BY_ID = 'getGroupById';
export const GET_USERS_BY_GROUP = 'getUsersByGroup';
export const UPDATE_GROUP = 'updateGroup';
export const DELETE_GROUP = 'deleteGroup';
export const SET_DEFAULT_HOLE_IMAGE = 'setDefaultHoleImage';

//FOR COUPONS
export const GET_ALL_COUPONS = 'getAllCoupons';
export const CREATE_COUPON = 'createCoupon';
export const DELETE_COUPON = 'deleteCoupon';

//FOR COURTS
export const GET_ALL_COURTS = 'getAllCourts';
export const GET_COURT_BY_ID = 'getCourtById';
export const CREATE_COURT = 'createCourt';
export const UPDATE_COURT = 'updateCourt';
export const DELETE_COURT = 'deleteCourt';

//FOR HOLES
export const GET_ALL_HOLES = 'getAllHoles';
export const GET_HOLES_BY_COURT = 'getHolesByCourt';
export const GET_HOLE_BY_ID = 'getHoleById';
export const CREATE_HOLE = 'createHole';
export const UPDATE_HOLE = 'updateHole';
export const DELETE_HOLE = 'deleteHole';
export const SET_DEFAULT_GROUP_IMAGE = 'setDefaultGroupImage';

//FOR CAMERAS
export const GET_ALL_CAMERAS = 'getAllCameras';
export const GET_CAMERAS_BY_HOLE = 'getCamerasByHole';
export const GET_CAMERA_BY_ID = 'getCameraById';
export const CREATE_CAMERA = 'createCameras';
export const UPDATE_CAMERA = 'updateCameras';
export const DELETE_CAMERA = 'deleteCameras';

//FOR RECORDINGS
export const GET_ALL_RECORDINGS = 'getAllRecordings';
export const GET_RECORDINGS_BY_HOLE = 'getRecordingsByHole';
export const GET_RECORDINGS_BY_USER = 'getRecordingsByUser';
export const GET_RECORDING_BY_ID = 'getRecordingById';
export const DELETE_RECORDING = 'deleteRecording';

//FOR TRANSACTIONS
export const GET_MONEY_TRANSACTIONS_BY_USER = 'getMoneyTransactionsByUser';
export const GET_ACE_MILES_TRANSACTIONS_BY_USER = 'getAceMilesTransactionsByUser';
export const ADD_MONEY = 'addMoney';

//FOR RECORDING COMMENTS
export const GET_COMMENTS_BY_RECORDING = 'getCommentsByRecording';
export const CREATE_RECORDING_COMMENT = 'createRecordingComment';

//FOR COMPANY COUPON POOLS
export const GET_ALL_COMPANY_COUPON_POOLS = 'getAllCompanyCouponPools';

//FOR COMPANY COUPONS
export const GIVE_COMPANY_COUPON = 'giveCompanyCoupon';

//FOR OTHER
export const TOGGLE_HIDE_NAVBAR = "toggleHideNavbar";




