<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>{{ group.group_name }}</h1>
        <delete-group-modal/>
        <h1 class="mb-3" style="margin-top: 3rem;">Parameters</h1>
        <update-group-parameters-modal/>
      </v-col>
      <v-col>
        <users-by-group/>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { GET_GROUP_BY_ID } from '@/store/actions.type';
import DeleteGroupModal from '../components/modals/DeleteGroupModal.vue';
import UpdateGroupParametersModal from "../components/modals/UpdateGroupParametersModal";

export default {
  components: { DeleteGroupModal, UpdateGroupParametersModal },
  name: 'Group',
    data(){
    return {

    }
  },
  created: function () {
    this.$store.dispatch(GET_GROUP_BY_ID, { groupId: this.$route.params.id })
  },
  computed: {
    group(){
        return this.$store.getters.getGroupById;
    }
  }
}
</script>

<style scoped>

</style>
