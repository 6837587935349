import axios from 'axios'
import JwtService from "../common/jwt.service";
import {
  CHECK_AUTH,
  LOGIN,
  LOGOUT
} from "./actions.type";
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
} from "./mutations.type";


const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
  getErrors: state => state.errors
};

const actions = {
  async [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {        
        axios({url: `/api/auth/login`, data: credentials, method: 'POST' })
        .then(resp => {
            context.commit(SET_AUTH, resp.data)
            resolve(resp)
        })
        .catch(err => {
            context.commit(SET_ERROR)
            localStorage.removeItem('token')
            reject(err)
        })
    })
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      axios({url: `/api/users/me`, method: 'GET' })
      .then(resp => {
          context.commit(SET_AUTH, resp.data)
      })
      .catch(err => {
        console.log(err)
        context.commit(PURGE_AUTH);
      })
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  }
};

const mutations = {

  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.errors = [];

    JwtService.saveToken(data.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
  },
  [SET_ERROR](state, errors) {
    state.errors = errors;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
