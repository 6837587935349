<template>
  <div>
   <!-- <form class="login" @submit.prevent="login">
     <h1>Sign in</h1>
     <label>Phone number</label>
     <input required v-model="phone_number" type="text" placeholder="Phone number"/>
     <label>Password</label>
     <input required v-model="password" type="password" placeholder="Password"/>
     <hr/>
     <button type="submit">Login</button>
   </form> -->

    <form @submit.prevent="login">

        <v-text-field
          v-model="phone_number"
          label="Phone Number"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          type="password"
          label="Password"
          required
        ></v-text-field>

      <v-btn
        class="mr-4"
        type="submit"
      >
        submit
      </v-btn>
    </form>
 </div>


 
</template>

<script>
import { LOGIN } from "../store/actions.type";

export default {
  data(){
    return {
      phone_number : "",
      password : ""
    }
  },
  methods: {
    async login() {
      let phone_number = this.phone_number
      let password = this.password
      await this.$store.dispatch(LOGIN, { phone_number, password })
      .then(() => this.$router.push('/'))
      .catch(err => console.log(err))
    }
  }
}
</script>