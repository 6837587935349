<template>
  <v-container>
    <v-row>
      <v-col>
        <court-count-info-card></court-count-info-card>
      </v-col>
      <v-col>
        <hole-count-info-card></hole-count-info-card>
      </v-col>
      <v-col>
        <recording-count-info-card></recording-count-info-card>
      </v-col>
      <!-- <v-col>
        <error-count-info-card></error-count-info-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <h1>Golfbanen</h1>
        <courts></courts>
      </v-col>
      <v-col>
        <h1>Opnames</h1>
        <recordings></recordings>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="d-flex justify-space-between align-center">
          <h1>Gebruikers</h1>
          <form action="" ref="form">
            <input id='import-users-file-input' ref="import_users_file" type='file' hidden @change="handleFileUpload"/>
            <v-btn id='import-users-btn' small color="primary">Add users</v-btn>
          </form>
        </div>
        <users></users>
      </v-col>
      <v-col cols="6">
        <div class="d-flex justify-space-between align-center">
          <h1>Groepen</h1>
        </div>
        <groups></groups>
      </v-col>
      <v-col>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Import {{this.userImportSuccess}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialog = false; userImportSuccess = ''"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <h1>Coupons</h1>
        </div>
        <coupons></coupons>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import Recordings from '../components/Recordings.vue'
import { IMPORT_USERS, GET_ALL_GROUPS } from "@/store/actions.type";
export default {
  components: { Recordings },
  name: 'Home',
  data () {
    return {
      userImportSuccess: '',
      dialog: false
    }
  },
  mounted() {
    document.getElementById('import-users-btn').addEventListener('click', this.openDialog);
  },
  methods: {
    openDialog() {
      document.getElementById('import-users-file-input').click();
    },
    handleFileUpload() {
      this.$store.dispatch(IMPORT_USERS, {importExcelSheet: this.$refs.import_users_file.files[0]})
      .then((resp) => {
        this.$store.dispatch(GET_ALL_GROUPS)
        this.dialog = true
        this.userImportSuccess = resp
        this.$refs.form.reset()
      })
    }
  }
}
</script>

<style scoped>

</style>
