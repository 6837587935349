<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Informatie</h1>
        <v-card class="mx-auto" tile>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Naam</v-list-item-title>
              <v-list-item-subtitle>{{ court.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Locatie</v-list-item-title>
              <v-list-item-subtitle>
                {{ court.zip }}, {{ court.country }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <h1>Holes</h1>
        <holes></holes>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {GET_COURT_BY_ID} from '../store/actions.type';

export default {
  name: 'Court',
  data() {
    return {}
  },
  created: function () {
    this.$store.dispatch(GET_COURT_BY_ID, {courtId: this.$route.params.id})
  },
  computed: {
    court() {
      return this.$store.getters.getCourtById;
    }
  }
}
</script>

<style scoped>

</style>
