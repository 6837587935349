import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors'


Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
          light: {
            primary: "#f87e5e",
            secondary: "#102f27",
            accent: "#0f8e38",
            error: colors.red.base,
            warning: colors.orange.base,
            info: colors.blue.base,
            success: "#0f8e38"
          },
        },
      },
});
