import axios from 'axios'
import {
  GET_ALL_COUPONS,
  CREATE_COUPON,
  DELETE_COUPON
} from "./actions.type";
import {
  SET_ALL_COUPONS
} from "./mutations.type";

const state = {
    couponErrors: [],
    allCoupons: [],
};

const getters = {
    getAllCoupons: state => state.allCoupons
};

const actions = {
    [GET_ALL_COUPONS](context) {
      axios({url: `/api/coupons`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_COUPONS, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [CREATE_COUPON](context, {coupon}) {
      axios({url: `/api/coupons`, method: 'POST', data: coupon})
        .then({
          context
        })
        .catch(err => {
          console.log(err)
        })
    },
    [DELETE_COUPON](context, { couponId }) {
      axios({url: `/api/coupons/${couponId}`, method: 'DELETE' })
      .then({

      })
      .catch(err => {
        console.log(err)
      })
    }
};

const mutations = {
  [SET_ALL_COUPONS](state, coupons) {
    state.allCoupons = coupons
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
