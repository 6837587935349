<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="1400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Bekijken
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Bericht</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <h1>Foto</h1><br>
                  <v-img
                  v-bind:src="comment.image_path"
                  width="500px"
                  height="auto"
                  ></v-img>
                </v-col>
                <v-col>
                  <h1>{{ comment.user.first_name }} {{ comment.user.last_name }}</h1><br>
                  <p>{{ comment.comment }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>

export default {
  data(){
    return {
      dialog: false
    }
  },
  props: {
    comment: {
        type: Object,
        default: () => ({}),
    },
  }
}
</script>
