<template>
  <v-container>
    <v-row>
      <v-col>
        <delete-recording-modal></delete-recording-modal>
        <video id="video" class="d-flex justify-center align-center" width="70%" height="auto" controls>
          <source :key="recording.id" :src="recording.recording_path" type="video/mp4">
        Your browser does not support the video tag.
        </video>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Berichten</h1>
        <recording-comments></recording-comments>
      </v-col>
      <v-col>
        <hole-info-card :hole="recording.hole"></hole-info-card>
      </v-col>
      <v-col :style="{display: recording.distance != null ? 'block' : 'none'}">
        <distance-info-card :recording="recording"></distance-info-card>
      </v-col>
    </v-row>
  </v-container>
    
</template>

<script>
import DistanceInfoCard from '../components/info-cards/DistanceInfoCard.vue'
import DeleteRecordingModal from '../components/modals/DeleteRecordingModal.vue'
import RecordingComments from '../components/RecordingComments.vue'
import { GET_RECORDING_BY_ID } from '../store/actions.type'
export default {
  components: { DeleteRecordingModal, DistanceInfoCard, RecordingComments },
  name: 'Recording',
  data(){
    return {

    }
  },
  mounted: function () {
    this.$store.dispatch(GET_RECORDING_BY_ID, { recordingId: this.$route.params.id })
  },
  computed: {
    recording(){
        return this.$store.getters.getRecordingById;
    }
  }
}
</script>

<style scoped>
#video {
  margin: auto;
}
</style>
