<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="1400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Bekijk afstand
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Bekijk afstand</span>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-carousel>
                <v-carousel-item>
                  <v-sheet
                    tile
                  >
                    <h1>Resultaat</h1>
                    <v-img
                    v-bind:src="recording.algorithm_result_path"
                    width="auto"
                    height="auto"
                    ></v-img>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item>
                  <v-sheet
                    tile
                  >
                    <h1>Vooraf</h1>
                    <v-img
                    v-bind:src="recording.algorithm_before_path"
                    width="auto"
                    height="auto"
                    ></v-img>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item>
                  <v-sheet
                    tile
                  >
                    <h1>Achteraf</h1>
                    <v-img
                    v-bind:src="recording.algorithm_after_path"
                    width="auto"
                    height="auto"
                    ></v-img>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-container>
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { GET_RECORDING_BY_ID } from '../../store/actions.type'

export default {
  data(){
    return {
      dialog: false
    }
  },
  created: function () {
    this.$store.dispatch(GET_RECORDING_BY_ID, { recordingId: this.$route.params.id })
  },
  computed: {
    recording() {
        return this.$store.getters.getRecordingById;
    }
  }
}
</script>
