<template>
  <div>
    <v-skeleton-loader v-if="loading" type="list-item-avatar-three-line"></v-skeleton-loader>
    <v-card
    v-if="!loading"
    class="mx-auto"
    max-width="344"
    outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Opnames
          </div>
          <v-list-item-title class="headline mb-1">
            {{ recordings.meta.total }}
          </v-list-item-title>
        </v-list-item-content>

        <v-icon size="60">mdi-play-circle-outline</v-icon>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { GET_ALL_RECORDINGS } from "../../store/actions.type";

export default {
  data(){
    return {
      loading: true
    }
  },
  created: function () {
    this.$store.dispatch(GET_ALL_RECORDINGS, { page: 0 })
  },
  computed: {
    recordings(){
        return this.$store.getters.getAllRecordings;
    }
  },
  watch: {
    recordings() {
      if(this.recordings.data !== undefined) {
        this.loading = false
      }
    }
  }
}
</script>