<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Creeër coupon
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Nieuwe coupon</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                        label="Naam*"
                        v-model="coupon.name"
                        :rules="rules.name"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Code*"
                        v-model="coupon.code"
                        :rules="rules.code"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Amount(€)*"
                        v-model="coupon.amount"
                        type="number"
                        :rules="rules.amount"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="Start Date*"
                        readonly
                        :value="coupon.start_date"
                    ></v-text-field>
                    <v-date-picker
                        locale="en-in"
                        v-model="coupon.start_date"
                        no-title
                        required
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="End Date*"
                        readonly
                        :value="coupon.end_date"
                    ></v-text-field>
                    <v-date-picker
                        locale="en-in"
                        v-model="coupon.end_date"
                        no-title
                        required
                    ></v-date-picker>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="group"
                      :items="groups"
                      label="Selecteer Groep"
                      item-text="group_name"
                      item-value="id"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*verplicht veld</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="createHole()"
            >
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import {CREATE_COUPON, GET_ALL_COUPONS} from '../../store/actions.type'

export default {
  data(){
    return {
      dialog: false,
      valid: "",
      group: "",
      coupon: {
        name: "",
        code: "",
        amount: "",
        start_date: "",
        end_date: "",
        group_id: 0
      },
      rules: {
        name: [
          v => !!v || 'Naam is verplicht',
          v => (v && v.length <= 50) || 'De naam mag niet langer dan 50 karakters zijn.',
        ],
        code: [
          v => !!v || 'Code is verplicht',
          v => (v && v.length <= 10) || 'De code mag niet langer dan 10 karakters zijn.',
        ],
        amount: [
          v => !!v || 'Amount is verplicht',
          v => (v && v.length <= 5) || 'De amount mag niet langer dan 5 karakters zijn.',
        ]
      }
    }
  },
  computed: {
    groups(){
        return this.$store.getters.getAllGroups
    }
  },
  methods: {
    createHole: function () {
      if(this.$refs.form.validate()) {
          this.coupon.group_id = this.group


        this.coupon.start_date = this.coupon.start_date + " 00:00:00"
        this.coupon.end_date   = this.coupon.end_date + " 00:00:00"
        this.$store.dispatch(CREATE_COUPON, { coupon: this.coupon})
        setTimeout(() => {
          this.$store.dispatch(GET_ALL_COUPONS);
        }, 500);

        this.dialog = false
        this.$refs.form.reset()
      }
    }
  }
}
</script>
