<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allGroups"
      item-key="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Zoeken"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.group="{ item }">
        <v-btn small color="primary" :to="{ name: 'getGroupById', params: {id: item.id} }">Group</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { GET_ALL_GROUPS } from "@/store/actions.type";

export default {
  data(){
    return {
        search: '',
        allGroups: []
    }
  },
  created: function () {
    this.$store.dispatch(GET_ALL_GROUPS)
  },
  computed: {
      headers () {
        return [
        { text: 'id', value: 'id' },
        { text: 'Name', value: 'group_name' },
        { text: '', value: 'group' }
      ]
    },
    getGroups(){
        return this.$store.getters.getAllGroups
    }
  },
  watch: {
    getGroups() {
      this.allGroups = this.getGroups
    }
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      console.log(item)
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
    },
  }
}
</script>
