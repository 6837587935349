import axios from 'axios'
import {
    GET_ALL_CAMERAS, GET_CAMERA_BY_ID, GET_CAMERAS_BY_HOLE, DELETE_CAMERA,
} from "./actions.type";
import {
    SET_ALL_CAMERAS, SET_CAMERA_BY_ID, SET_CAMERAS_BY_HOLE
} from "./mutations.type";

const state = {
    cameraErrors: [],
    allCameras: [],
    camerasByHole: [],
    cameraById: {}
};

const getters = {
    getAllCameras: state => state.allCameras,
    getCamerasByHole: state => state.camerasByHole,
    getCameraById: state => state.cameraById
};

const actions = {
    [GET_ALL_CAMERAS](context) {
      axios({url: `/api/cameras`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_CAMERAS, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_CAMERAS_BY_HOLE](context, { holeId }) {
      axios({url: `/api/holes/${holeId}/cameras`, method: 'GET' })
      .then(resp => {
          context.commit(SET_CAMERAS_BY_HOLE, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_CAMERA_BY_ID](context, { cameraId }) {
      axios({url: `/api/cameras/${cameraId}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_CAMERA_BY_ID, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [DELETE_CAMERA]({ userId }) {
      axios({url: `/api/users/${userId}`, method: 'DELETE' })
      .then({

      })
      .catch(err => {
        console.log(err)
      })
    },
};

const mutations = {
  [SET_ALL_CAMERAS](state, cameras) {
    state.allCameras = cameras
  },
  [SET_CAMERAS_BY_HOLE](state, cameras) {
    state.camerasByHole = cameras
  },
  [SET_CAMERA_BY_ID](state, camera) {
    state.cameraById = camera
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
