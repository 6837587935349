

<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Creeër hole
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Nieuwe hole</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Naam*"
                      v-model="hole.name"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*verplicht veld</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="createHole()"
            >
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { CREATE_HOLE } from '../../store/actions.type'
//import { CREATE_USER } from '../../store/actions.type'

export default {
  data(){
    return {
      dialog: false,
      valid: "",
      hole: {
        name: ""
      },
      rules: {
        name: [
          v => !!v || 'Naam is verplicht',
          v => (v && v.length <= 50) || 'De naam mag niet langer dan 50 karakters zijn.',
        ],
      }
    }
  },
  created: function () {

  },
  computed: {

  },
  methods: {
    createHole: function () {
      if(this.$refs.form.validate()) {
        this.$store.dispatch(CREATE_HOLE, { hole: this.hole, courtId: 2 })
        this.dialog = false
        this.$refs.form.reset()
      }
    }
  }
}
</script>