import axios from 'axios'
import { GIVE_COMPANY_COUPON } from './actions.type';

const state = {

};

const getters = {

};

const actions = {
  [GIVE_COMPANY_COUPON](context, { userId, companyCouponPoolId }) {
    axios({url: `/api/company-coupon-pools/${companyCouponPoolId}/users/${userId}/give`, method: 'POST' })
    .then({

    })
    .catch(err => {
      console.log(err)
    })
  },
};

const mutations = {

};

export default {
  state,
  actions,
  mutations,
  getters
};
