<template>
  <div>
    <create-coupon-modal/>
    <v-data-table
      :headers="headers"
      :items="allCoupons.slice().reverse()"
      item-key="id"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Zoeken"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn small color="red" @click="deleteCoupon(item.id)" style="color: #fff">Delete</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CreateCouponModal from './modals/CreateCouponModal.vue';
import { GET_ALL_COUPONS, DELETE_COUPON} from "../store/actions.type";

export default {
  components: {
    CreateCouponModal
  },
  data(){
    return {
        search: '',
        allCoupons: []
    }
  },
  created: function () {
    this.$store.dispatch(GET_ALL_COUPONS)
  },
  computed: {
      headers () {
        return [
          { text: 'id', value: 'id' },
          { text: 'Name', value: 'name' },
          { text: 'Code', value: 'code' },
          { text: 'Amount', value: 'amount' },
          { text: 'Start Date', value: 'start_date' },
          { text: 'End Date', value: 'end_date' },
          { text: '', value: 'delete' }
      ]
    },
    getCoupons(){
        return this.$store.getters.getAllCoupons
    }
  },
  watch: {
    getCoupons() {
      this.allCoupons = this.getCoupons
    }
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      console.log(item)
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
    },
    deleteCoupon(id) {
      this.$store.dispatch(DELETE_COUPON, {couponId: id})
      setTimeout(() => {
        this.$store.dispatch(GET_ALL_COUPONS);
        }, 500);
    }
  }
}
</script>
