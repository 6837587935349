<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
    <v-row>
      <v-col cols="12">
        <div class="mb-5">
          <v-text-field
              label="First text field*:"
              v-model="firstTextField"
              :rules="rules.name"
              required/>
        </div>
        <div>
          <v-text-field
              label="Second text field*:"
              v-model="secondTextField"
              :rules="rules.name"
              required/>
          <div>
        </div>
        <div>
          <v-checkbox
            v-model="isActive"
            :label="`Is hole active: ${isActive.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="isAlgorithmActive"
            :label="`Is algorithm active: ${isAlgorithmActive.toString()}`"
          ></v-checkbox>
        </div>
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex flex-column">
              <v-btn id='change-default-video-image-btn' class="mb-4" small color="primary" @click="openDialog">
                <img :src="defaultImageSrc"
                     alt="default video image"
                     style="height: 150px; width: 150px">
              </v-btn>
              <v-btn @click="setDefaultImage">Set default image</v-btn>
            </div>
            <v-btn id='update-hole-btn' small color="primary" @click="updateHoleParameters">Update hole parameters</v-btn>
            <input id='change-default-video-image-input' ref="import_users_file" type='file' hidden @change="defaultVideoImageUpload"/>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_HOLE_BY_ID, SET_DEFAULT_HOLE_IMAGE, UPDATE_HOLE } from '../../store/actions.type'

export default {
  data(){
    return {
      valid: '',
      firstTextField: '',
      secondTextField: '',
      isActive: false,
      isAlgorithmActive: false,
      file: null,
      defaultImageSrc: '',
      rules: {
        name: [
          v => !!v || 'Naam is verplicht',
          v => (v && v.length <= 150) || 'De naam mag niet langer dan 150 karakters zijn.',
        ],
      }
    }
  },
  mounted() {
    this.$store.dispatch(GET_HOLE_BY_ID, { holeId: this.$route.params.id })
  },
  methods: {
    openDialog() {
      document.getElementById('change-default-video-image-input').click();
    },
    defaultVideoImageUpload(e) {
      this.file = e.target.files[0];
      this.defaultImageSrc = URL.createObjectURL(this.file);
    },
    async updateHoleParameters() {
      let payload = {
        holeId: this.$route.params.id,
        data: {
          custom_text_message1: this.firstTextField,
          custom_text_message2: this.secondTextField,
          is_active: this.isActive,
          is_algorithm_active: this.isAlgorithmActive
        }
      }
      if (this.file !== null) {
        const imageBase64 = await this.blobToData(this.file)
        payload.data.default_video_image = imageBase64
      }

      await this.$store.dispatch(UPDATE_HOLE, payload)
    },
    blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    setDefaultImage() {
      this.$store.dispatch(SET_DEFAULT_HOLE_IMAGE, { holeId: this.$route.params.id });
      setTimeout(() => {
        this.$store.dispatch(GET_HOLE_BY_ID, { holeId: this.$route.params.id })
      }, 500);
    }
  },
  computed: {
    ...mapGetters(['getHoleById'])
  },
  watch: {
    getHoleById: function() {
      this.firstTextField = this.getHoleById.custom_text_message1;
      this.secondTextField = this.getHoleById.custom_text_message2;
      this.isActive = this.getHoleById.is_active;
      this.isAlgorithmActive = this.getHoleById.is_algorithm_active;
      this.defaultImageSrc = this.getHoleById.default_video_image;
    }
  }
}
</script>

<style scoped>
#change-default-video-image-btn {
  width: max-content;
  height: max-content;
  padding: 0;
}
</style>
