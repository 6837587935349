<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
    >
      <a class="d-flex align-center" href="/">
        <v-img
          alt="The Ace Challenge Logo"
          class="shrink mr-2"
          contain
          src="@/assets/ace_logo.svg"
          transition="scale-transition"
          width="200"
        />

        <!-- <v-img
          alt="The Ace Challenge"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/logo_text.png"
          width="150"
        /> -->
      </a>

      

      <v-spacer></v-spacer>

      <v-btn @click="logOut()" icon>
        <v-icon color="primary">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main color="primary">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import JwtService from "./common/jwt.service";

export default {
  name: 'App',
  metaInfo: {
    title: 'Ace Challenge Admin Dashboard'
  },
  components: {
  },

  data: () => ({

  }),
  created: function () {
    // Set the auth token for any request
    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
      //config.baseURL = "http://127.0.0.1:8000"
      //config.baseURL = "https://api.theacechallenge.com"
      config.baseURL = "https://api.ace.beta.spinnov.com"
      return config;
    });

    // Last step: handle request error general case
    axios.interceptors.response.use(
      response => response,
      error => {
        // Error
        const { response: { status } } = error;

        if (status === 401) {
          JwtService.destroyToken()
          this.$router.push('/login')
        }
      }
    );
  },
  methods: {
    logOut() {
      JwtService.destroyToken()
      this.$router.push('/login')
    }
  }
};
</script>
