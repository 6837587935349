import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Court from '../views/Court.vue'
import Hole from '../views/Hole.vue'
import User from '../views/User.vue'
import Group from '../views/Group.vue'
import Recording from '../views/Recording.vue'
import Login from '../components/Login.vue'


Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Hoofdpagina',
      }

    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login',
      }
    },
    {
      path: '/court/:id',
      name: 'getCourtById',
      component: Court,
      meta: {
        title: 'Golfbaan',
      },
    },
    {
      path: '/hole/:id',
      name: 'getHoleById',
      component: Hole,
      meta: {
        title: 'Hole',
      },
    },
    {
      path: '/recording/:id',
      name: 'getRecordingById',
      component: Recording,
      meta: {
        title: 'Opname',
      },
    },
    {
      path: '/user/:id',
      name: 'getUserById',
      component: User,
      meta: {
        title: 'Gebruiker',
      },
    },
    {
      path: '/group/:id',
      name: 'getGroupById',
      component: Group,
      meta: {
        title: 'Groups',
      },
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  next()
})

export default router
