<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Zoeken"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.full_name="{ item }">
        <span>{{ item.first_name }}</span> <span>{{ item.prefix }}</span> <span>{{ item.last_name }}</span>
      </template>
      <template v-slot:item.profile="{ item }">
        <v-btn small color="primary" :to="{ name: 'getUserById', params: {id: item.id} }">Profiel</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {GET_USERS_BY_GROUP} from "@/store/actions.type";

export default {
  data(){
    return {
        search: '',
    }
  },
  created: function () {
    this.$store.dispatch(GET_USERS_BY_GROUP, { groupId: this.$route.params.id })
  },
  computed: {
      headers () {
        return [
        { text: 'id', value: 'id' },
        { text: 'Telefoonnummer', value: 'phone_number' },
        { text: 'Volledige naam', value: 'full_name' },
        { text: '', value: 'profile' }
      ]
    },
    users(){
        return this.$store.getters.getUsersByGroup
    }
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      console.log(item)
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
    },
  }
}
</script>
