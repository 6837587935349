<template>
    <v-card
    class="mx-auto"
    max-width="344"
    outlined
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="overline mb-4">
            Hole
            </div>
            <v-list-item-title class="headline mb-1">
            {{ hole.name }}
            </v-list-item-title>
        </v-list-item-content>

        <v-icon size="60">mdi-flag-triangle</v-icon>
        </v-list-item>
    </v-card>
</template>

<script>

export default {
  data(){
    return {

    }
  },
  props: {
    hole: {
        type: Object,
        default: () => ({}),
    },
  },
}
</script>