import axios from 'axios'
import {
  DELETE_USER,
    GET_ALL_USERS, GET_USER_BY_ID,
  IMPORT_USERS
} from "./actions.type";
import {
    SET_ALL_USERS, SET_USER_BY_ID
} from "./mutations.type";

const state = {
    userErrors: [],
    allUsers: {},
    userById: {}
};

const getters = {
    getAllUsers: state => state.allUsers,
    getUserById: state => state.userById
};

const actions = {
    [GET_ALL_USERS](context, { page }) {
      axios({url: `/api/users?page=${page}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_USERS, resp.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_USER_BY_ID](context, { userId }) {
      axios({url: `/api/users/${userId}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_USER_BY_ID, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [DELETE_USER](context, { userId }) {
      axios({url: `/api/users/${userId}`, method: 'DELETE' })
      .then({

      })
      .catch(err => {
        console.log(err)
      })
    },
  [IMPORT_USERS](context, { importExcelSheet }) {
    let formData = new FormData();
    formData.append('file', importExcelSheet);
    return axios.post(`/api/users/import`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp != null && (resp.status > 200 || resp.status < 210)) {
          return 'is successful'
        } else {
          return 'has failed'
        }
      })
      .catch(() => {
        return 'has failed'
      })
  }
};

const mutations = {
  [SET_ALL_USERS](state, users) {
    state.allUsers = users
  },
  [SET_USER_BY_ID](state, user) {
    state.userById = user
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
