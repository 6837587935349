<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
    <v-row>
      <v-col cols="12">
        <div class="mb-5">
          <v-text-field
              label="Group Name*:"
              v-model="groupName"
              :rules="rules.name"
              required/>
        </div>
        <div>
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex flex-column">
              <v-btn id='change-default-video-image-btn' class="mb-3" small color="primary" @click="openDialog">
                <img :src="defaultImageSrc"
                     alt="default video image"
                     style="height: 150px; width: 150px">
              </v-btn>
              <v-btn @click="setDefaultImage">Set default image</v-btn>
            </div>
            <v-btn id='update-group-btn' small color="primary" @click="updateGroupParameters">Update Group parameters
            </v-btn>
            <input id='change-default-video-image-input' type='file' hidden
                   @change="defaultVideoImageUpload"/>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters} from 'vuex';
import { GET_GROUP_BY_ID, UPDATE_GROUP, SET_DEFAULT_GROUP_IMAGE } from "../../store/actions.type";

export default {
  data() {
    return {
      valid: '',
      groupName: '',
      file: null,
      defaultImageSrc: '',
      rules: {
        name: [
          v => !!v || 'Naam is verplicht',
          v => (v && v.length <= 150) || 'De naam mag niet langer dan 150 karakters zijn.',
        ],
      }
    }
  },
  mounted() {
    this.$store.dispatch(GET_GROUP_BY_ID, {groupId: this.$route.params.id})
  },
  methods: {
    openDialog() {
      document.getElementById('change-default-video-image-input').click();
    },
    defaultVideoImageUpload(e) {
      this.file = e.target.files[0];
      this.defaultImageSrc = URL.createObjectURL(this.file);
    },
    async updateGroupParameters() {
      let payload = {
        groupId: this.$route.params.id,
        data: {
          group_name: this.groupName
        }
      }
      if (this.file !== null) {
        const imageBase64 = await this.blobToData(this.file)
        payload.data.default_video_image = imageBase64
      }

      await this.$store.dispatch(UPDATE_GROUP, payload)
    },
    blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    setDefaultImage() {
      this.$store.dispatch(SET_DEFAULT_GROUP_IMAGE, { groupId: this.$route.params.id });
      setTimeout(() => {
        this.$store.dispatch(GET_GROUP_BY_ID, { groupId: this.$route.params.id })
      }, 500);
    }
  },
  computed: {
    ...mapGetters(['getGroupById'])
  },
  watch: {
    getGroupById: function () {
      this.groupName = this.getGroupById.group_name;
      if (this.getGroupById.default_video_image) {
        this.defaultImageSrc = this.getGroupById.default_video_image;
      } else {
        this.defaultImageSrc = 'https://i.stack.imgur.com/y9DpT.jpg'
      }
    }
  }
}
</script>

<style scoped>
#change-default-video-image-btn {
  width: max-content;
  height: max-content;
  padding: 0;
}
</style>
