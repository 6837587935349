<template>
    <!-- <div>
      
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Naam
              </th>
              <th class="text-left">
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="court in courts"
              :key="court.id"
            >
              <td>{{ court.name }}</td>
              <td><v-btn small color="primary" :to="{ name: 'getCourtById', params: {id: court.id} }">Naar golfbaan</v-btn></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div> -->

  <div>
    <create-court-modal></create-court-modal>
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    <v-simple-table class="table" v-show="!loading">
      <thead>
        <tr>
          <th>Naam</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="court in courts.data" :key="court.id">
          <td>{{ court.name }}</td>
          <td><v-btn small color="primary" :to="{ name: 'getCourtById', params: {id: court.id} }">Naar golfbaan</v-btn></td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination :data="courts" :limit="3" @pagination-change-page="changePage" v-show="!loading">
      <template #prev-nav>
        <span>&lt; Previous</span>
      </template>
      <template #next-nav>
          <span>Next &gt;</span>
      </template>
    </pagination>
  </div>
</template>

<script>
import { GET_ALL_COURTS } from "../store/actions.type";
import CreateCourtModal from './modals/CreateCourtModal.vue';

export default {
  components: { CreateCourtModal },
  data(){
    return {
      search: '',
      loading: true    }
  },
  created: function () {
    this.changePage()
  },
  methods: {
    changePage(page = 0) {
      this.$store.dispatch(GET_ALL_COURTS, { page: page })
    }
  },
  computed: {
    courts() {
        return this.$store.getters.getAllCourts;
    }
  },
  watch: {
    courts() {
      if(this.courts.data !== undefined) {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="css" scoped>
.pagination {
display: -ms-flexbox;
display: flex;
padding-left: 0;
flex-direction: row;
justify-content: space-between;
list-style: none;
border-radius: 0.25rem;
}

.page-link {
position: relative;
display: block;
padding: 0.5rem 0.75rem;
margin-left: -1px;
line-height: 1.25;
color: #007bff;
background-color: #fff;
border: 1px solid #dee2e6;
}

.page-link:hover {
z-index: 2;
color: #0056b3;
text-decoration: none;
background-color: #e9ecef;
border-color: #dee2e6;
}

.page-link:focus {
z-index: 3;
outline: 0;
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
margin-left: 0;
border-top-left-radius: 0.25rem;
border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
border-top-right-radius: 0.25rem;
border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
z-index: 3;
color: #fff;
background-color: #007bff;
border-color: #007bff;
}

.page-item.disabled .page-link {
color: #6c757d;
pointer-events: none;
cursor: auto;
background-color: #fff;
border-color: #dee2e6;
}

.pagination-lg .page-link {
padding: 0.75rem 1.5rem;
font-size: 1.25rem;
line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
border-top-left-radius: 0.3rem;
border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
border-top-right-radius: 0.3rem;
border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
padding: 0.25rem 0.5rem;
font-size: 0.875rem;
line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
border-top-left-radius: 0.2rem;
border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
border-top-right-radius: 0.2rem;
border-bottom-right-radius: 0.2rem;
}</style>