<template>
  <v-card
  class="mx-auto"
  max-width="344"
  outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          Fouten (7 dagen)
        </div>
        <v-list-item-title class="headline mb-1">
          {{ errors.length }}
        </v-list-item-title>
      </v-list-item-content>

      <v-icon size="60" color="red">mdi-information-outline</v-icon>
    </v-list-item>
  </v-card>
</template>

<script>
import { GET_ALL_RECORDINGS } from "../../store/actions.type";

export default {
  data(){
    return {

    }
  },
  created: function () {
    this.$store.dispatch(GET_ALL_RECORDINGS)
  },
  computed: {
    errors(){
        return this.$store.getters.getAllRecordings.filter(recording => recording.is_error === 1);
    }
  }
}
</script>