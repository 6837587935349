import axios from 'axios'
import {
    GET_ALL_RECORDINGS, GET_RECORDING_BY_ID, GET_RECORDINGS_BY_USER, DELETE_RECORDING, GET_RECORDINGS_BY_HOLE
} from "./actions.type";
import {
    SET_ALL_RECORDINGS, SET_RECORDING, SET_RECORDINGS_BY_HOLE, SET_RECORDINGS_BY_USER
} from "./mutations.type";

const state = {
    recordingErrors: [],
    allRecordings: {},
    recordingsByHole: {},
    recordingsByUser: {},
    recordingById: {}
};

const getters = {
    getAllRecordings: state => state.allRecordings,
    getRecordingById: state => state.recordingById,
    getRecordingsByUser: state => state.recordingsByUser,
    getRecordingsByHole: state => state.recordingsByHole
};

const actions = {
    [GET_ALL_RECORDINGS](context, { page }) {
      axios({url: `/api/recordings?page=${page}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_RECORDINGS, resp.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_RECORDINGS_BY_HOLE](context, { holeId, page }) {
      axios({url: `/api/holes/${holeId}/recordings?page=${page}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_RECORDINGS_BY_HOLE, resp.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_RECORDINGS_BY_USER](context, { userId, page }) {
      axios({url: `/api/users/${userId}/recordings?page=${page}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_RECORDINGS_BY_USER, resp.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_RECORDING_BY_ID](context, { recordingId }) {
      axios({url: `/api/recordings/${recordingId}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_RECORDING, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [DELETE_RECORDING]({ recordingId }) {
      axios({url: `/api/recordings/${recordingId}`, method: 'GET' })
      .then({
      })
      .catch(err => {
        console.log(err)
      })
    },
};

const mutations = {
  [SET_ALL_RECORDINGS](state, recordings) {
    state.allRecordings = recordings
  },
  [SET_RECORDINGS_BY_USER](state, recordings) {
    state.recordingsByUser = recordings
  },
  [SET_RECORDINGS_BY_HOLE](state, recordings) {
    state.recordingsByHole = recordings
  },
  [SET_RECORDING](state, recording) {
    state.recordingById = recording
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
