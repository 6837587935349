import axios from 'axios'
import { GET_ACE_MILES_TRANSACTIONS_BY_USER, GET_MONEY_TRANSACTIONS_BY_USER } from "./actions.type";
import { ADD_MONEY, SET_ACE_MILES_TRANSACTIONS_BY_USER, SET_MONEY_TRANSACTIONS_BY_USER } from "./mutations.type";

const state = {
    transactionErrors: [],
    moneyTransactionsByUser: [],
    aceMilesTransactionsByUser: []
};

const getters = {
  getMoneyTransactionsByUser: state => state.moneyTransactionsByUser,
  getAceMilesTransactionsByUser: state => state.aceMilesTransactionsByUser,
};

const actions = {
    [GET_MONEY_TRANSACTIONS_BY_USER](context, { userId }) {
      axios({url: `/api/users/${userId}/transaction-history`, method: 'GET' })
      .then(resp => {
        context.commit(SET_MONEY_TRANSACTIONS_BY_USER, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_ACE_MILES_TRANSACTIONS_BY_USER](context, { userId }) {
      axios({url: `/api/users/${userId}/ace-miles-transaction-history`, method: 'GET' })
      .then(resp => {
        context.commit(SET_ACE_MILES_TRANSACTIONS_BY_USER, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [ADD_MONEY](context, { userId, data }) {
      axios({url: `/api/users/${userId}/addMoney`, method: 'POST', data: data })
      .then(resp => {
        context.commit(SET_ACE_MILES_TRANSACTIONS_BY_USER, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
};

const mutations = {
  [SET_MONEY_TRANSACTIONS_BY_USER](state, moneyTransactions) {
    state.moneyTransactionsByUser = moneyTransactions
    
  },
  [SET_ACE_MILES_TRANSACTIONS_BY_USER](state, aceMilesTransactions) {
    state.aceMilesTransactionsByUser = aceMilesTransactions
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
