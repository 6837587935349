import axios from 'axios'
import {
  GET_ALL_GROUPS,
  GET_GROUP_BY_ID,
  GET_USERS_BY_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  SET_DEFAULT_GROUP_IMAGE,
} from "./actions.type";
import {
  SET_ALL_GROUPS,
  SET_GROUP_BY_ID,
  SET_USERS_BY_GROUP
} from "./mutations.type";

const state = {
    groupErrors: [],
    allGroups: [],
    groupById: {},
    usersByGroup: []
};

const getters = {
    getAllGroups: state => state.allGroups,
    getGroupById: state => state.groupById,
    getUsersByGroup: state => state.usersByGroup
};

const actions = {
    [GET_ALL_GROUPS](context) {
      axios({url: `/api/groups`, method: 'GET' })
      .then(resp => {
          context.commit(SET_ALL_GROUPS, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_GROUP_BY_ID](context, { groupId }) {
      axios({url: `/api/groups/${groupId}`, method: 'GET' })
      .then(resp => {
          context.commit(SET_GROUP_BY_ID, resp.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    },
    [GET_USERS_BY_GROUP](context, {groupId}) {
      axios({url: `/api/groups/${groupId}/users`, method: 'GET' })
        .then(resp => {
          context.commit(SET_USERS_BY_GROUP, resp.data.data);
        })
        .catch(err => {
          console.log(err)
        })
    },
    [UPDATE_GROUP](context, payload) {
      axios({url: `/api/groups/${payload.groupId}`, method: 'PATCH', data: payload.data})
        .then({
          context
        })
        .catch(err => {
          console.log(err)
        })
    },
    [DELETE_GROUP](context, { groupId }) {
      axios({url: `/api/groups/${groupId}`, method: 'DELETE' })
        .then({

        })
        .catch(err => {
          console.log(err)
        })
    },
    [SET_DEFAULT_GROUP_IMAGE](context, { groupId }) {
      axios({url: `/api/groups/${groupId}/setdefault`, method: 'POST' })
        .then({

        })
        .catch(err => {
          console.log(err)
        })
    }
};

const mutations = {
  [SET_ALL_GROUPS](state, groups) {
    state.allGroups = groups
  },
  [SET_GROUP_BY_ID](state, group) {
    state.groupById = group
  },
  [SET_USERS_BY_GROUP](state, users) {
    state.usersByGroup = users
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
