

<template>
    <div>
        <v-dialog
            v-model="isOpen"
            max-width="500px"
            >
            <v-card>
                <v-card-title>
                Gebruiker verwijderen
                </v-card-title>
                <v-card-text>
                    Weet u zeker dat u deze gebruiker wilt verwijderen?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="grey"
                        text
                        @click="isOpen=false"
                    >
                        Sluiten
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="deleteUser()"
                    >
                        Verwijderen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn small color="error" @click="isOpen=!isOpen">Verwijder Gebruiker</v-btn>
    </div>
</template>

<script>
import { DELETE_USER } from '../../store/actions.type'
import router from '../../router'

export default {
  data(){
    return {
      isOpen: false
    }
  },
  created: function () {

  },
  computed: {

  },
  methods: {
    deleteUser: function () {
      this.$store.dispatch(DELETE_USER, { userId: this.$route.params.id })
      router.push('/')
    }
  }
}
</script>
