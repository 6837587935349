<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Geef coupon
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Geef coupon</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="companyCouponPool"
                      :items="companyCouponPools"
                      label="Selecteer Coupon"
                      item-text="name"
                      item-value="id"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*verplicht veld</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="giveCoupon()"
            >
              Toevoegen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { GET_ALL_COMPANY_COUPON_POOLS } from '../../store/actions.type'
import { GIVE_COMPANY_COUPON } from '../../store/actions.type'

export default {
  data(){
    return {
      companyCouponPool: {},
      dialog: false,
      valid: "",
      group: ""
    }
  },
  created: function () {
    this.$store.dispatch(GET_ALL_COMPANY_COUPON_POOLS)
  },
  computed: {
    companyCouponPools(){
        return this.$store.getters.getAllCompanyCouponPools
    }
  },
  methods: {
    giveCoupon: function () {
      if(this.$refs.form.validate()) {
        this.$store.dispatch(GIVE_COMPANY_COUPON, { userId: this.$route.params.id, companyCouponPoolId: this.companyCouponPool})
        this.dialog = false
        this.$refs.form.reset()
      }
    }
  }
}
</script>
