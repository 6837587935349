import axios from 'axios'
import {
    GET_COMMENTS_BY_RECORDING
} from "./actions.type";
import {
    SET_COMMENTS_BY_RECORDING
} from "./mutations.type";

const state = {
    commentsByRecording: {},
};

const getters = {
    getCommentsByRecording: state => state.commentsByRecording
};

const actions = {
  [GET_COMMENTS_BY_RECORDING](context, { recordingId, page }) {
    axios({url: `/api/recordings/${recordingId}/comments?page=${page}`, method: 'GET' })
    .then(resp => {
      context.commit(SET_COMMENTS_BY_RECORDING, resp.data);
    })
    .catch(err => {
      console.log(err)
    })
  },
};

const mutations = {
  [SET_COMMENTS_BY_RECORDING](state, commentsByRecording) {
    state.commentsByRecording = commentsByRecording
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
