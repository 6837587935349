

<template>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            Creeër golfbaan
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Nieuwe golfbaan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Naam*"
                      v-model="court.name"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                      :items="['Nederland', 'België', 'Duitsland', 'Engeland']"
                      label="Land*"
                      v-model="court.country"
                      :rules="rules.country"
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      label="Postcode*"
                      hint="1234AB"
                      v-model="court.zip"
                      :rules="rules.zip"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      label="Woonplaats*"
                      v-model="court.city"
                      :rules="rules.city"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      label="Straatnummer*"
                      hint="42"
                      v-model="court.streetNumber"
                      :rules="rules.streetNumber"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*verplicht veld</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Sluiten
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="createCourt()"
            >
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { CREATE_COURT } from '../../store/actions.type'
//import { CREATE_USER } from '../../store/actions.type'

export default {
  data(){
    return {
      dialog: false,
      valid: "",
      court: {
        name: "",
        country: "",
        city: "",
        zip: "",
        streetNumber: ""
      },
      rules: {
        name: [
          v => !!v || 'Naam is verplicht',
          v => (v && v.length <= 50) || 'De naam mag niet langer dan 50 karakters zijn.',
        ],
        city: [
          v => !!v || 'Woonplaats is verplicht',
          v => (v && v.length <= 50) || 'De naam mag niet langer dan 50 karakters zijn.',
        ],
        country: [
          v => !!v || 'Land is verplicht',
          v => (v && v.length <= 50) || 'Mag niet langer dan 50 karakters zijn.',
        ],
        zip: [
          v => !!v || 'Postcode is verplicht',
          v => /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i.test(v) || 'Postcode is ongeldig',
        ],
        streetNumber: [
          v => !!v || 'Straatnummer is verplicht',
          v => !isNaN(v) || 'Straatnummer is ongeldig',
        ],
      }
    }
  },
  created: function () {

  },
  computed: {

  },
  methods: {
    createCourt: function () {
      if(this.$refs.form.validate()) {
        this.$store.dispatch(CREATE_COURT, { court: this.court })
        this.dialog = false
        this.$refs.form.reset()
      }
    }
  }
}
</script>