// FOR AUTH
export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";

//FOR USERS
export const SET_ALL_USERS = 'setAllUsers';
export const SET_USER_BY_ID = 'setUserById';

//FOR GROUPS
export const SET_ALL_GROUPS = 'setAllGroups';
export const SET_GROUP_BY_ID = 'setGroupById';
export const SET_USERS_BY_GROUP = 'setUsersByGroup';

//FOR COUPONS
export const SET_ALL_COUPONS = 'setAllCoupons';

//FOR COURTS
export const SET_ALL_COURTS = 'setAllCourts';
export const SET_COURT_BY_ID = 'setCourtById';

//FOR HOLES
export const SET_ALL_HOLES = 'setAllHoles';
export const SET_HOLES_BY_COURT = 'setHolesByCourt';
export const SET_HOLE_BY_ID = 'setHoleById';

//FOR RECORDINGS
export const SET_ALL_RECORDINGS = 'setAllRecordings';
export const SET_RECORDINGS_BY_USER = 'setRecordingsByUser';
export const SET_RECORDINGS_BY_HOLE = 'setRecordingsByHole';
export const SET_RECORDING = 'setRecording';

//FOR CAMERAS
export const SET_ALL_CAMERAS = 'setAllCameras';
export const SET_CAMERAS_BY_HOLE = 'setCamerasByHole';
export const SET_CAMERA_BY_ID = 'setCameraById';

//FOR TRANSACTIONS
export const SET_MONEY_TRANSACTIONS_BY_USER = 'setMoneyTransactionsByUser';
export const SET_ACE_MILES_TRANSACTIONS_BY_USER = 'setAceMilesTransactionsByUser';
export const ADD_MONEY = 'addMoney';

//FOR RECORDING COMMENTS
export const SET_COMMENTS_BY_RECORDING = 'setCommentsByRecording';

//FOR COMPANY COUPONS
export const SET_ALL_COMPANY_COUPON_POOLS = 'setAllCompanyCouponPools';

//FOR OTHER
export const SET_HIDE_NAVBAR = "setHideNavbar";
